import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-temporary-maintenance',
  templateUrl: './temporary-maintenance.component.html',
  styleUrls: ['./temporary-maintenance.component.scss'],
  imports: [TranslateModule],
})
export class TemporaryMaintenanceComponent {}
