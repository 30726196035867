import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, tap, take, map, filter, timeout, catchError, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AuthService } from 'src/app/_shared/services';
import { TokenKey } from 'src/app/_shared/enum/auth-token.enum';
import * as fromStore from 'src/app/_store/_reducers';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard {
  private hasInitialized = false;

  constructor(private auth: AuthService, private router: Router, private store: Store<fromStore.State>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const token = localStorage.getItem(TokenKey.TOKEN_KEY) || null;
    if (token) {
      sessionStorage.setItem(TokenKey.TOKEN_KEY, token);
      localStorage.removeItem(TokenKey.TOKEN_KEY);
    }

    // attempt to renew the token (if any)
    return this.auth.renewToken(state.url).pipe(
      // switch the stream to the auth observable
      switchMap(() => {
        return this.auth.authed.pipe(
          take(1),
          // if we have a token at this point, allow navigation; otherwise return a UrlTree to the login page
          switchMap((payload) => {
            if (payload) {
              if (this.hasInitialized) {
                return of(true);
              }
              this.hasInitialized = true;

              let mpId = state.root.queryParams.mpId;
              return this.store.select(fromStore.getUserPreferences).pipe(
                switchMap((userPref) => {
                  if (!state.root.queryParams.mpId) {
                    mpId = userPref?.mpId;
                  }
                  const criticalStoreObservable: Array<Observable<any>> = [
                    this.store.select(fromStore.getUser).pipe(startWith(false)),
                    this.store.select(fromStore.getFeaturesState).pipe(startWith(false)),
                  ];
                  if (mpId) {
                    criticalStoreObservable.push(
                      ...[
                        this.store.select(fromStore.getMp).pipe(startWith(false)),
                        this.store.select(fromStore.getAlarmChannel).pipe(startWith(false)),
                        this.store.select(fromStore.getTrendsChannels).pipe(startWith(false)),
                        this.store.select(fromStore.getMeterChannels).pipe(startWith(false)),
                      ]
                    );
                  }
                  return combineLatest(criticalStoreObservable).pipe(
                    filter((storeStates) =>
                      storeStates.every((state) => state !== null && state !== undefined && state)
                    ),
                    map((storeStates) => storeStates.every((state) => state)),
                    take(1),
                    timeout({
                      each: 60000,
                      with: () => {
                        alert('An error has occurred: press ok to reload the page.');
                        return of(false);
                      },
                    }),
                    map(() => {
                      return true;
                    })
                  );
                })
              );
            } else {
              return of(this.router.parseUrl('/login'));
            }
          }),
          catchError((error) => {
            return of(this.router.parseUrl('/login'));
          }),
          // re-parse the token to kick off a fresh call to user/account api
          tap((p) => (p === true ? this.auth.reload() : null))
        );
      })
    );
  }
}
