import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom, takeUntil } from 'rxjs/operators';
import { forkJoin, of, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import moment from 'moment';

import * as fromUser from 'src/app/_store/_reducers';
import { AuthService, UnauthorizedService, DiligentApiService } from 'src/app/_shared/services';
import { UserActions } from 'src/app/_store/_user/actions';
import { InitActions } from 'src/app/_store/_init/actions';
import { RecommendationActions } from 'src/app/_store/_recommendation/actions';
import { IAccount } from 'src/app/_shared/classes/account';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import * as fromCharts from 'src/app/_store/_reducers';
import { MeasurementPoint } from 'src/app/_shared/classes/MeasurementPoint';
import { ChartsActions } from 'src/app/_store/_charts/actions';
import { RangeSelectorActions } from 'src/app/_store/_range-selector/actions';
import { ChannelsActions } from 'src/app/_store/_channels/actions';
import { MapActions } from 'src/app/_store/_map/actions';
import { TrendAlarmModelService } from 'src/app/_shared/services/trend-alarm-model.service';
import { QubescanDashboardActions } from 'src/app/_store/_qubescan-dashboard/actions';
import { MpType } from 'src/app/_shared/enum/measurementPoint.enum';

@Injectable()
export class UserEffects {
  initUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.initUser.type),
      withLatestFrom(this.store.select(fromUser.getUserPreferences)),
      switchMap(([action, userPreferences]) => {
        this.isLogin = action.payload.isLogin;
        return forkJoin([of(userPreferences), of(action.payload.getMpParams)]);
      }),
      switchMap(([userPreferences, getMpParams]) => {
        return this.trendAlarmModelService.getTrendAlarmChannelList(getMpParams).pipe(
          switchMap((test) => {
            return forkJoin([of(test), of(userPreferences), of(getMpParams)]);
          }),
          catchError((error) => {
            return forkJoin([of(null), of(userPreferences), of(getMpParams)]);
          }),
          switchMap(([alarmList, userPreferences, getMpParams]) => {
            return [
              UserActions.setAlarmChannelSuccess({ payload: { channel: alarmList } }),
              MapActions.initMap({
                payload: {
                  center: userPreferences.mapCenter ? userPreferences.mapCenter : null,
                  zoomFactor: userPreferences.zoomFactor ? userPreferences.zoomFactor : null,
                },
              }),
              getMpParams
                ? QubescanDashboardActions.getKPI({ payload: { mpId: getMpParams } })
                : UserActions.emptyActions(),
            ];
          })
        );
      })
    )
  );

  getAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getAccount.type),
      switchMap((action) => {
        return this.authService.getAccountStore(action.payload.accountId).pipe(
          map((account: IAccount) =>
            UserActions.getAccountSuccess({
              payload: {
                account: { ...account },
              },
            })
          )
        );
      })
    )
  );

  setAlarmChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setAlarmChannel.type),
      switchMap((action) => {
        return this.trendAlarmModelService.getTrendAlarmChannelList(action.payload.mpId).pipe(
          map((channel) => {
            return UserActions.setAlarmChannelSuccess({
              payload: {
                channel: channel,
              },
            });
          })
        );
      })
    )
  );

  getMp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getMp.type),
      withLatestFrom(this.store.select(fromUser.getUserPreferences)),
      switchMap(([action, userPreferences]) => {
        if (action.payload.mpId) {
          return this.mpService.getMeasurementPoint(action.payload.mpId).pipe(
            switchMap((mp) => {
              mp.measurementPointId = mp.roomId;
              return [
                UserActions.getMpSuccess({
                  payload: {
                    mp: new MeasurementPoint(mp),
                    returnUrl: action.payload.returnUrl,
                  },
                }),
                UserActions.setFirmwareVersionSuccess({
                  payload: { firmwareVersion: mp.firmwareVersion },
                }),
                UserActions.setHeaderName({
                  payload: {
                    accountName: mp.accountName,
                    site: mp.locationName,
                    mpName: mp.mpId,
                    timezone: mp.timezone,
                  },
                }),
                ChannelsActions.setAllChannels({
                  payload: {
                    mpId: mp.measurementPointId,
                    mpTypeId: mp.measurementPointTypeId,
                  },
                }),
                ChartsActions.initCharts({
                  payload: {
                    startDate: null,
                    scope: 'day',
                    timezone: mp.timezone,
                    granularity: 'minute',
                    interval: 1,
                    commissionedWhen: mp.commissionedWhen,
                    usePreCommissionStart: userPreferences?.usePreCommissionStart
                      ? userPreferences.usePreCommissionStart
                      : false,
                  },
                }),
                RangeSelectorActions.initRangeSelectorReport({
                  payload: {
                    timezone: mp.timezone,
                    commissionedWhen: mp.commissionedWhen,
                    usePreCommissionStart: userPreferences?.usePreCommissionStart
                      ? userPreferences.usePreCommissionStart
                      : false,
                  },
                }),
                RangeSelectorActions.initRangeSelectorGlobal({
                  payload: {
                    timezone: mp.timezone,
                    commissionedWhen: mp.commissionedWhen,
                    usePreCommissionStart: userPreferences?.usePreCommissionStart
                      ? userPreferences.usePreCommissionStart
                      : false,
                  },
                }),
              ];
            }),
            catchError((error) => {
              return [
                UserActions.unauthorizedMp({
                  payload: { error, mpId: action.payload.mpId },
                }),
              ];
            })
          );
        } else {
          return [InitActions.login({ payload: { returnUrl: action.payload.returnUrl } })];
        }
      })
    )
  );

  getMpSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getMpSuccess.type),
      withLatestFrom(this.store.select(fromUser.getUserPreferences)),
      switchMap(([action, userPreferences]) => {
        const actions = [];
        if (action.payload.mp.mMeasurementPointTypeId === MpType.IN_SITE) {
          actions.push(
            RecommendationActions.getRecommendationSuccess({
              payload: { recommendations: null },
            }),
            RecommendationActions.getRecommendationCount({
              payload: { mpId: action.payload.mp.mMeasurementPointId },
            })
          );
        }
        if (userPreferences.mpId !== action.payload.mp.mMeasurementPointId) {
          actions.push(
            UserActions.getUserPreferencesSuccess({
              payload: {
                userPreferences: {
                  ...userPreferences,
                  account: action.payload.mp.mAccountId,
                  mpId: action.payload.mp.mMeasurementPointId,
                },
              },
            })
          );
        }
        if (action.payload.from === 'channel' || action.payload.from === 'change-selection') {
          if (action.payload.returnUrl) {
            this.router.navigateByUrl(action.payload.returnUrl);
          }
          return [...actions, InitActions.loginSuccess()];
        } else {
          if (!this.isLogin) {
            return [...actions, InitActions.login({ payload: { returnUrl: action.payload.returnUrl } })];
          } else {
            if (action.payload.returnUrl) {
              // this.router.navigateByUrl(action.payload.returnUrl);
            }
            return [...actions, InitActions.loginSuccess()];
          }
        }
      })
    )
  );

  setHeaderName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setHeaderName.type),
      map((action) => {
        if (action.payload.timezone) {
          this.timezone = moment.tz.zone(action.payload.timezone).abbr(moment().valueOf());
        }
        let headerName =
          action.payload.accountName +
          ' | ' +
          action.payload.site +
          ' | ' +
          action.payload.mpName +
          ' | (' +
          this.timezone +
          ')';
        return UserActions.setHeaderNameSuccess({ payload: { headerName: headerName } });
      })
    )
  );

  unauthorizedMp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.unauthorizedMp.type),
      withLatestFrom(this.store.select(fromUser.getUserPreferences)),
      switchMap(([action, userPreferences]) => {
        userPreferences = {
          ...userPreferences,
          account: null,
          mpId: null,
        };
        this.authService.savePreferences({ ...userPreferences });
        return this.unauthorizedService.notifyWrongIdAccount().pipe(
          switchMap(() => {
            return [
              UserActions.initUser({
                payload: {
                  accountId: userPreferences.account,
                  isLogin: false,
                },
              }),
            ];
          })
        );
      })
    )
  );

  updateUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserPreferences.type),
      withLatestFrom(this.store.select(fromUser.getUserPreferences)),
      map(([action, userPreferences]) => {
        let updateUserPreferences = cloneDeep(userPreferences);
        Object.keys(action.payload.property).map((property) => {
          updateUserPreferences[property] = action.payload.property[property];
        });

        this.authService.savePreferences(updateUserPreferences, true);
        return UserActions.updateUserPreferencesSuccess();
      })
    )
  );

  initViewerMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.initViewerMode.type),
      withLatestFrom(this.store.select(fromUser.getUserId), this.store.select(fromUser.getUserPreferences)),
      switchMap(([action, userId, userPreferences]) => {
        return this.diligentApiService.getUserById(userId, userPreferences.account).pipe(
          map((user) => {
            return UserActions.setViewerMode({
              payload: { viewer: user.isSystemAdministrator === 0 && user.name === 'Viewer' },
            });
          })
        );
      })
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUser.type),
      switchMap((action) => {
        return this.diligentApiService.getUserById(action.payload.id, action.payload.accountId).pipe(
          switchMap((user) => {
            return [UserActions.getUserSuccess({ user: user }), UserActions.initViewerMode()];
          })
        );
      })
    )
  );

  getUserPreferencesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserPreferencesSuccess.type),
      map((action) => {
        return UserActions.initViewerMode();
      })
    )
  );

  removeMp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.removeMp.type),
      map((action) => {
        this.router.navigate([], {
          queryParams: {
            mpId: null,
            account: null,
          },
          queryParamsHandling: 'merge',
        });
        return UserActions.updateUserPreferences({
          payload: { property: { mpId: null, account: null } },
        });
      })
    )
  );

  startCommissionning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.startCommissionning.type),
      switchMap((action) => {
        return timer(120000, 120000).pipe(
          switchMap(() => {
            return this.mpService.getMeasurementPoint(action.payload.mpId).pipe(
              switchMap((mp) => {
                if (mp.measurementPointStatusId > 4) {
                  mp.measurementPointId = mp.roomId;
                  return [
                    InitActions.changeSelection({
                      payload: {
                        point: mp,
                        redirect: this.router.url.substring(0, this.router.url.indexOf('?')),
                        onBoarding: { isOnBoarding: false, timestamp: null },
                      },
                    }),
                    UserActions.setMpStatusSuccess({ payload: { mp: mp } }),
                  ];
                } else {
                  return [UserActions.emptyActions()];
                }
              })
            );
          }),
          takeUntil(this.actions$.pipe(ofType(UserActions.setMpStatusSuccess)))
        );
      })
    )
  );

  private isLogin: boolean;
  private timezone: string;

  constructor(
    private actions$: Actions<UserActions.UserActionsUnion>,
    private authService: AuthService,
    private mpService: MeasurementPointsService,
    private unauthorizedService: UnauthorizedService,
    private trendAlarmModelService: TrendAlarmModelService,
    private router: Router,
    private store: Store<fromCharts.State>,
    private diligentApiService: DiligentApiService
  ) {}
}
