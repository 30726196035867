import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { map, Observable, take } from 'rxjs';

import * as fromStore from 'src/app/_store/_reducers';
import { WelcomeParameters } from 'src/app/_shared/interface/welcome-parameters.interface';
import { UserActions } from 'src/app/_store/_user/actions';
import { WelcomeFeature } from 'src/app/_shared/classes/features';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss'],
  imports: [MatDialogTitle, MatIconButton, MatIcon, MatButton, AsyncPipe, TranslateModule],
})
export class WelcomeDialogComponent {
  firstName: string;
  welcomeFeatureFlag$: Observable<WelcomeFeature>;
  constructor(
    public dialogRef: MatDialogRef<WelcomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: WelcomeParameters,
    private store: Store<fromStore.State>
  ) {
    this.firstName = data.firstName;
    this.welcomeFeatureFlag$ = this.store.select(fromStore.getFeatureHeader).pipe(
      take(1),
      map((headerFeatureFlag) => headerFeatureFlag.welcome)
    );
  }

  closeDialog(): void {
    this.store.dispatch(
      UserActions.updateUserPreferences({
        payload: { property: { welcomeMessage: false } },
      })
    );
    this.dialogRef.close();
  }
}
