import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { filter, interval, take } from 'rxjs';
import { RouterOutlet } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private swUpdate: SwUpdate,
    private notificationsService: NotificationsService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer
  ) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')); // Or whatever path you placed mdi.svg at
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/powerside-icon.svg'));
    matIconRegistry.addSvgIcon('doc-archive', domSanitizer.bypassSecurityTrustResourceUrl('./assets/archive_icon.svg'));
    matIconRegistry.addSvgIcon(
      'pqube',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/PQUBE3_Blue_Icon.svg')
    );
    matIconRegistry.addSvgIcon(
      'psl',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/insite-logo_icon.svg')
    );
    matIconRegistry.addSvgIcon(
      'bad-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-red.svg')
    );
    matIconRegistry.addSvgIcon(
      'good-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-green.svg')
    );
    matIconRegistry.addSvgIcon(
      'insufficient-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-blue.svg')
    );
    matIconRegistry.addSvgIcon(
      'no-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-white.svg')
    );
    matIconRegistry.addSvgIcon(
      'uncommissioned',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-grey.svg')
    );
    matIconRegistry.addSvgIcon(
      'delete-circle-plain',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/delete-circle-plain.svg')
    );
    matIconRegistry.addSvgIcon(
      'firmware-update',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/firmware-update.svg')
    );
    this.translate.setDefaultLang('en_US');
    this.checkUpdate();
  }

  checkUpdate(): void {
    let dialog;
    if (this.swUpdate.isEnabled) {
      interval(600000).subscribe(async () => {
        const test = await this.swUpdate.checkForUpdate();

        this.swUpdate.versionUpdates
          .pipe(
            filter((evt): any => {
              return evt.type === 'VERSION_READY';
            })
          )
          .subscribe((evt) => {
            if (!dialog) {
              dialog = this.notificationsService
                .alert(this.translate.instant('update.body'), this.translate.instant('update.title'))
                .afterClosed()
                .pipe(take(1))
                .subscribe(() => {
                  document.location.reload();
                });
            }
          });
      });
    }
  }
}
