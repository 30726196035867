import { UpdateService } from './_shared/services/update.service';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler } from '@angular/core';

import packageJson from 'package.json';
import { InitProvider } from './_shared/provider/init.provider';
import { TranslateService } from '@ngx-translate/core';
import { GlobalErrorHandler } from './_shared/services/global-error-handler.service';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?no-cache=' + packageJson.version);
}

export function initProviderFactory(provider: InitProvider): () => Promise<unknown> {
  return (): Promise<unknown> => provider.load();
}

export const appConfig: ApplicationConfig = {
  providers: [
    TranslateService,
    InitProvider,
    UpdateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitProvider],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
};
