import { enableProdMode, APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { PrimeThemePreset } from './app/prime-theme-preset';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from 'src/app/_store/_reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { bootstrapApplication } from '@angular/platform-browser';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { ChannelsEffects } from 'src/app/_store/_channels/effects/channels.effects';
import { DrivescanDashboardEffects } from 'src/app/_store/_drivescan-dashboard/effects/drivescan-dashboard.effects';
import { HierarchyEffects } from 'src/app/_store/_hierarchy/effects/hierarchy.effects';
import { UserEffects } from 'src/app/_store/_user/effects/user.effects';
import { InitEffects } from 'src/app/_store/_init/effects/init.effects';
import { ChartsEffects } from 'src/app/_store/_charts/effects/charts.effects';
import { RecommendationEffects } from 'src/app/_store/_recommendation/effects/recommendation.effects';
import { AuthEffects } from 'src/app/_store/_auth/effects/auth.effects';
import { MaintenanceEffects } from 'src/app/_store/_maintenance/effects/maintenance.effects';
import { QubescanDashboardEffects } from 'src/app/_store/_qubescan-dashboard/effects/qubescan-dashboard.effects';
import { MapEffects } from 'src/app/_store/_map/effects/map.effects';
import { NewsEffects } from 'src/app/_store/_news/effects/news.effects';
import { RolesEffects } from 'src/app/_store/_roles/effects/roles.effects';
import { RangeSelectorEffects } from 'src/app/_store/_range-selector/effects/range-selector.effects';
import { FeaturesEffects } from 'src/app/_store/_features/effects/features.effect';
import { AppComponent } from './app/app.component';
import {
  AuthService,
  ChartDefinitions,
  DiligentApiService,
  PowerQualityMeasuresService,
  SitesService,
  TokenService,
} from './app/_shared/services';
import { NotificationsService } from './app/_shared/modules/notifications/shared/notifications.service';
import { EnumationsService } from './app/_shared/services/enumerations.service';
import { DSTPipe } from './app/_shared/pipes/dst.pipe';
import { createTranslateLoader, initProviderFactory } from './app/app.config';
import { environment } from './environments/environment';
import { InitProvider } from 'src/app/_shared/provider/init.provider';
import { UpdateService } from 'src/app/_shared/services/update.service';
import { GlobalErrorHandler } from 'src/app/_shared/services/global-error-handler.service';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { DocumentService } from './app/_shared/modules/document/document.service';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      MatMomentDateModule,
      ButtonModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      AppRoutingModule,
      StoreModule.forRoot(reducers, { metaReducers }),
      StoreDevtoolsModule.instrument({
        name: 'NgRx In Site Store App',
        connectInZone: true,
      }),
      EffectsModule.forRoot([
        ChannelsEffects,
        DrivescanDashboardEffects,
        HierarchyEffects,
        UserEffects,
        InitEffects,
        ChartsEffects,
        RecommendationEffects,
        AuthEffects,
        MaintenanceEffects,
        QubescanDashboardEffects,
        MapEffects,
        NewsEffects,
        RolesEffects,
        RangeSelectorEffects,
        FeaturesEffects,
      ]),
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.pwa }),
      MonacoEditorModule.forRoot()
    ),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: PrimeThemePreset,
        options: {
          darkModeSelector: false || 'none',
        },
      },
    }),
    TranslateService,
    InitProvider,
    UpdateService,
    DiligentApiService,
    TokenService,
    SitesService,
    AuthService,
    NotificationsService,
    ChartDefinitions,
    EnumationsService,
    PowerQualityMeasuresService,
    DocumentService,
    DSTPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitProvider],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
