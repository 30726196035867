import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import {
  MatExpansionPanel,
  MatAccordion,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { IUser } from 'src/app/_shared/classes/user';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import * as fromStore from 'src/app/_store/_reducers';
import { InitActions } from 'src/app/_store/_init/actions';
import { StateFilter } from 'src/app/_shared/interface/state-filter.interface';
import { SitesService } from 'src/app/_shared/services';
import { FilterComponent } from '../../../filters/filter.component';
import { EventTrackingDirective } from 'src/app/_shared/tracking/tracking.directive';

@Component({
  selector: 'app-change-selection',
  templateUrl: './change-selection.component.html',
  styleUrls: ['./change-selection.component.scss'],
  imports: [
    MatDialogTitle,
    MatIconButton,
    MatIcon,
    FilterComponent,
    CdkScrollable,
    MatDialogContent,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    NgClass,
    MatTooltip,
    EventTrackingDirective,
    ProgressSpinnerModule,
    TranslateModule,
  ],
})
export class ChangeSelectionComponent extends OnDestroyMixin implements AfterViewInit, OnInit, OnDestroy {
  @ViewChildren('accountPanel') accountPanel: QueryList<MatExpansionPanel>;

  isAdmin = false;
  isSysAdmin: boolean;
  disableAnimation = true;
  loading = true;
  currentAccount: any;
  stateOptions: Array<StateFilter> = [
    {
      name: this.translateService.instant('measurementPoint.status.4'),
      id: [4, 5, 6, 7],
    },
    {
      name: this.translateService.instant('measurementPoint.status.8'),
      id: [8],
    },
    {
      name: this.translateService.instant('measurementPoint.status.11'),
      id: [11],
    },
    {
      name: this.translateService.instant('measurementPoint.status.9'),
      id: [9],
    },
  ];
  searchFilter = '';
  stateFilter = [];
  filtersPreferenceName = 'fleet';
  searchFilterReg = new RegExp('');
  filteredFleetList;
  partners;
  emptyList = false;
  private currentMpId: any;
  private user: IUser;

  constructor(
    public dialogRef: MatDialogRef<ChangeSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromStore.State>,
    private translateService: TranslateService,
    private sitesService: SitesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(untilComponentDestroyed(this)).subscribe((params: ParamMap) => {
      forkJoin([
        this.store.pipe(select(fromStore.getUser)).pipe(take(1)),
        this.store.pipe(select(fromStore.getMp)).pipe(take(1)),
        this.store.pipe(select(fromStore.getIsSysAdmin)).pipe(take(1)),
        this.sitesService.getSites().pipe(take(1)),
      ]).subscribe(([user, mp, isSysAdmin, sites]) => {
        if (mp) {
          this.currentAccount = mp.accountId;
          this.currentMpId = mp.roomId;
        }

        if (params.get('account')) {
          this.currentAccount = parseInt(params.get('account'), 10);
        }

        if (params.get('mpId')) {
          this.currentMpId = parseInt(params.get('mpId'), 10);
        }

        // get the logged in user
        this.user = user;

        // get the user's account
        if (!this.user) {
          return;
        }
        this.isAdmin = user.role.name === 'Administrator';
        this.isSysAdmin = isSysAdmin;
        this.initPartners(sites.partners);
        this.loading = false;
      });
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.disableAnimation = false));
    const currentAccountId = this.accountPanel.toArray().findIndex((e) => e.expanded === true);
    document.getElementById(this.accountPanel.toArray()[currentAccountId]?._headerId)?.scrollIntoView();
    if (this.currentMpId) {
      document.getElementsByClassName('row-' + this.currentMpId)[0].classList.add('selected-mp');
      document.getElementById('button-' + this.currentMpId).classList.add('selected-mp');
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  selectPoint(point, customer): void {
    point = {
      ...point,
      isDemoAccount: customer?.isDemoAccount,
    };
    const currentURL = (this.router && this.router.url) || '';

    let navigateURL: string;
    if (currentURL.indexOf('?') !== -1) {
      navigateURL = currentURL.substring(0, currentURL.indexOf('?'));
    } else {
      navigateURL = currentURL;
    }

    if (
      currentURL.toLowerCase().includes('event-graph') ||
      currentURL.toLowerCase().includes('fleet') ||
      (point.measurementPointTypeId !== 1 && currentURL.toLowerCase().includes('hf-emissions')) ||
      (point.measurementPointTypeId !== 2 && currentURL.toLowerCase().includes('reports'))
    ) {
      if (point.measurementPointStatusId === 9) {
        navigateURL = '/charts';
      } else {
        navigateURL = '/dashboard';
      }
    }

    this.store.dispatch(
      InitActions.changeSelection({
        payload: { point, redirect: navigateURL, onBoarding: { isOnBoarding: false, timestamp: null } },
      })
    );
    this.dialogRef.close();
  }

  searchFilterChange(event): any {
    this.searchFilter = event;
    let temp = event.split(' ');

    let regEx = '';
    temp.forEach((word) => {
      regEx += word + '.*';
    });
    regEx = regEx.slice(0, -2);
    this.searchFilterReg = new RegExp(regEx, 'i');
    this.filterFleet();
  }

  stateFilterChange(event): any {
    this.stateFilter = event;

    this.filterFleet();
  }

  clearFilters(value: boolean): void {
    if (value) {
      this.stateFilter = [];
      this.searchFilter = '';
      this.searchFilterReg = new RegExp('');
      this.filteredFleetList = null;
    }
  }

  private initPartners(partners): void {
    let filteredPartners = partners;
    partners
      .map((partner) => {
        let filteredCustomers = partner.customers
          .map((customer) => {
            let filteredMp = customer.measurementPoints.filter((mp) => {
              return !(
                (this.isSysAdmin || (mp.measurementPointTypeId !== 1 && this.isAdmin)
                  ? mp.measurementPointStatusId < 4 || mp.measurementPointStatusId > 9
                  : mp.measurementPointStatusId !== 8 && mp.measurementPointStatusId !== 9) &&
                mp.measurementPointStatusId !== 11
              );
            });

            customer = {
              ...customer,
              measurementPoints: filteredMp,
            };
            if (filteredMp.length > 0) {
              return customer;
            }
          })
          .filter((customer) => customer);

        partner = {
          ...partner,
          customers: filteredCustomers,
        };
        return filteredCustomers.length > 0 ? partner : null;
      })
      .filter((partner) => partner);

    this.partners = filteredPartners;
  }

  private filterFleet(): any {
    if (this.stateFilter.length === 0 && this.searchFilter === '') {
      this.clearFilters(true);
    } else {
      this.filteredFleetList = new Array(this.partners.length);
      this.emptyList = true;
      this.partners.map((partner, iPartner) => {
        if (
          !this.searchFilterReg.test('') &&
          partner.accountName.match(this.searchFilterReg) !== null &&
          this.stateFilter.length === 0
        ) {
          this.filteredFleetList[iPartner] = true;
          this.emptyList = false;
        } else {
          this.filteredFleetList[iPartner] = new Array(partner.customers.length);
          partner.customers.map((customer, iCustomer) => {
            if (
              !this.searchFilterReg.test('') &&
              customer.accountName.match(this.searchFilterReg) !== null &&
              this.stateFilter.length === 0
            ) {
              this.filteredFleetList[iPartner][iCustomer] = true;
              this.emptyList = false;
            } else {
              if (customer?.measurementPoints.length === 0) {
                this.filteredFleetList[iPartner][iCustomer] = false;
              } else {
                this.filteredFleetList[iPartner][iCustomer] = new Array(customer?.measurementPoints.length);
                customer?.measurementPoints?.map((mp, iMp) => {
                  if (
                    this.stateFilter.length === 0
                      ? ((mp.mpId !== null && mp.mpId?.match(this.searchFilterReg) !== null) ||
                          (mp.locationName !== null && mp.locationName?.match(this.searchFilterReg) !== null)) &&
                        (this.stateFilter.length <= 0 ||
                          this.stateFilter.some((e) => e.id.includes(mp.measurementPointStatusId)))
                      : ((mp.accountName !== null && mp.accountName?.match(this.searchFilterReg) !== null) ||
                          (mp.partnerName !== null && mp.partnerName?.match(this.searchFilterReg) !== null) ||
                          (mp.mpId !== null && mp.mpId?.match(this.searchFilterReg) !== null) ||
                          (mp.locationName !== null && mp.locationName?.match(this.searchFilterReg) !== null)) &&
                        (this.stateFilter.length <= 0 ||
                          this.stateFilter.some((e) => e.id.includes(mp.measurementPointStatusId)))
                  ) {
                    this.filteredFleetList[iPartner][iCustomer][iMp] = true;
                    this.emptyList = false;
                    this.filteredFleetList[iPartner][iCustomer].count
                      ? this.filteredFleetList[iPartner][iCustomer].count++
                      : (this.filteredFleetList[iPartner][iCustomer].count = 1);
                  } else {
                    this.filteredFleetList[iPartner][iCustomer][iMp] = false;
                  }
                });
              }
              if (
                this.filteredFleetList[iPartner][iCustomer] &&
                !this.filteredFleetList[iPartner][iCustomer].includes(true)
              ) {
                this.filteredFleetList[iPartner][iCustomer] = false;
              }
            }
          });
          if (
            this.filteredFleetList[iPartner] &&
            this.filteredFleetList[iPartner].every((val, i, arr) => val === arr[0]) &&
            this.filteredFleetList[iPartner].includes(false)
          ) {
            this.filteredFleetList[iPartner] = false;
          }
        }
      });
    }
  }
}
