import { Customer } from 'src/app/_shared/interface/customer.interface';

export interface Partner {
  accountId?: number;
  accountName?: string;
  customers?: Array<Customer>;
  expand?: boolean;
  subscriptionType?: string;
  childSubscriptionType?: string;
  totalMp?: number;
}

/**
 * return if one of the partners have a customer with
 * a commissioned mp
 * @param parteners List of Partner
 */
export function canSelectFromPartners(parteners: Partner[]): boolean {
  if (!parteners) return false;
  for (const partner of parteners) {
    for (const customer of partner.customers) {
      if (customer.isDemoAccount !== 1) {
        for (const mp of customer.measurementPoints) {
          if (mp.measurementPointStatusId >= 8 && mp.measurementPointStatusId < 12) {
            return true;
          }
        }
      }
    }
  }
  return false;
}
