import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AnonymousLayoutComponent } from 'src/app/layout/anonymous-layout/anonymous-layout.component';
import { AppLayoutComponent } from 'src/app/layout/app-layout/app-layout.component';
import { IsAuthenticatedGuard } from 'src/app/_shared/guards/route.guards';
import { TemporaryMaintenanceComponent } from './temporary-maintenance/temporary-maintenance.component';
import { Maintenance } from 'src/environments/temporary-maintenance';

let routes: Routes;

if (Maintenance) {
  routes = [
    {
      path: '**',
      redirectTo: '',
    },
    {
      path: '',
      component: TemporaryMaintenanceComponent,
    },
  ];
} else {
  routes = [
    {
      path: '',
      canActivate: [IsAuthenticatedGuard],
      runGuardsAndResolvers: 'always',
      component: AppLayoutComponent,
      children: [
        { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
        {
          path: 'dashboard',
          loadChildren: (): Promise<any> =>
            import('./dashboard/dashboard.routing').then((r) => r.DashboardRoutingModule),
        },
        {
          path: 'charts',
          loadChildren: (): Promise<any> => import('./charts/charts.routing').then((r) => r.ChartsRoutingModule),
        },
        {
          path: 'charts/:mpId/:accountId/:alarmId',
          loadChildren: (): Promise<any> => import('./charts/charts.routing').then((r) => r.ChartsRoutingModule),
        },
        {
          path: 'energy-power',
          loadChildren: (): Promise<any> =>
            import('./energy-power/energy-power.routing').then((r) => r.EnergyPowerRoutingModule),
        },
        {
          path: 'hf-emissions',
          loadChildren: (): Promise<any> =>
            import('./hf-emissions/hf-emissions.routing').then((r) => r.HfEmissionsRoutingModule),
        },
        {
          path: 'reports',
          loadChildren: (): Promise<any> =>
            import('./reports-recommendations/reports-recommendations.routing').then(
              (r) => r.ReportsRecommendationRoutingModule
            ),
        },
        {
          path: 'report-writer',
          loadChildren: (): Promise<any> =>
            import('./report-writer/report-writer.routing').then((r) => r.ReportWriterRoutingModule),
        },
        {
          path: 'monthly-report/:accountId/:mpId/:year/:month',
          redirectTo: '/reports/monthly/:accountId/:mpId/:year/:month',
        },
        {
          path: 'fleet',
          loadChildren: (): Promise<any> =>
            import('./fleet-overview/fleet-overview.routing').then((r) => r.FleetOverviewRoutingModule),
        },
        {
          path: 'event-graph/:mpId/:accountId/:deviceEventId/:deviceEventTypeId/:triggeredWhen',
          loadChildren: (): Promise<any> =>
            import('./event-graph/event-graph.routing').then((r) => r.EventGraphRoutingModule),
        },
        {
          path: 'events-list',
          loadChildren: (): Promise<any> =>
            import('./events-list/events-list.routing').then((r) => r.EventsListRoutingModule),
        },
        {
          path: 'more-information',
          loadChildren: (): Promise<any> =>
            import('./information/information.routing').then((r) => r.InformationRoutingModule),
        },
        {
          path: 'profile',
          loadChildren: (): Promise<any> => import('./profile/profile.routing').then((r) => r.ProfileRoutingModule),
        },
        {
          path: 'manage-members',
          loadChildren: (): Promise<any> =>
            import('./manage-members/manage-members.routing').then((r) => r.ManageMembersRoutingModule),
        },
        {
          path: 'admin',
          loadChildren: (): Promise<any> => import('./sysadmin/sysadmin.routing').then((r) => r.SysAdminRoutingModule),
        },
        {
          path: 'notification',
          loadChildren: (): Promise<any> =>
            import('./notification/notification.routing').then((r) => r.NotificationRoutingModule),
        },
        {
          path: 'rms-variation-analysis',
          loadChildren: (): Promise<any> =>
            import('./rms-variation-analysis/rms-variation-analysis.routing').then(
              (r) => r.RmsVariationAnalysisRoutingModule
            ),
        },
        {
          path: 'maintenance',
          loadChildren: (): Promise<any> =>
            import('./maintenance/maintenance.routing').then((r) => r.MaintenanceRoutingModule),
        },
      ],
    },
    {
      path: '',
      component: AnonymousLayoutComponent,
      children: [
        { path: 'passwordRecovery', redirectTo: 'login/password-recovery' },
        { path: 'password-recovery', redirectTo: 'login/password-recovery' },
        {
          path: 'login',
          loadChildren: (): Promise<any> =>
            import('./qubescan-login/qubescan-login.routing').then((r) => r.QubescanLoginRoutingModule),
        },
        {
          path: 'drivescan',
          loadChildren: (): Promise<any> => import('./login/login.routing').then((r) => r.LoginRoutingModule),
        },
        {
          path: 'qubescan',
          redirectTo: 'login',
        },
        {
          path: 'register',
          loadChildren: (): Promise<any> =>
            import('./activation/activation.routing').then((r) => r.ActivationRoutingModule),
        },
      ],
    },
  ];
}
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
